import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { getSearchParams } from '@libs-components/utils/search-params'
import cookieLib from '@/libs/cookie'
import sendPageViewEvent from '@/features/tracking/events/send-page-view-event'
import { PIXEL_CONVERSION_API_PARAMS } from '@/features/tracking/pixel'
import { utmsUtils } from '@/features/tracking'
import InitGtag from './init-gtag'
import InitGtm from './init-gtm'
import InitPixel from './init-pixel'
import { InitJamesLineTag } from './init-james-line-tag'
import { InitLineTag } from './init-line-tag'
import { InitDcardAds } from './init-dcard-ads'
import { InitGoogleAdsense } from './init-google-adsense'

const InitTracking = () => {
  const router = useRouter()
  const { t } = useTranslation()

  useEffect(() => {
    utmsUtils.saveToCookie()
    ;(() => {
      const searchParams = getSearchParams()
      if (!searchParams?.has(PIXEL_CONVERSION_API_PARAMS)) return

      const currentTimestamp = new Date().getTime()
      const threeMonthMs = 3 * 30 * 24 * 60 * 60 * 1000
      const expireTimestamp = currentTimestamp + threeMonthMs
      cookieLib.set({
        key: PIXEL_CONVERSION_API_PARAMS,
        value: `fb.1.${currentTimestamp}.${searchParams.get(PIXEL_CONVERSION_API_PARAMS)}`,
        expires: new Date(expireTimestamp),
        domain: process.env.NEXT_PUBLIC_ROOT_DOMAIN as string,
      })
    })()
  }, [])

  useEffect(() => {
    const onRouteChangeComplete = () => sendPageViewEvent(t)
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router.events, t])

  return (
    <>
      <InitGtag />
      <InitGtm />
      <InitPixel />
      <InitJamesLineTag />
      <InitLineTag />
      <InitDcardAds />
      <InitGoogleAdsense />
    </>
  )
}

export default InitTracking
