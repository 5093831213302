import type { PropsWithChildren } from 'react'
import NavBar from '@/components/layouts/common/navbar'
import { useInit } from './use-init'

const CommonLayout = ({ children }: PropsWithChildren) => {
  useInit()

  return (
    <div className='relative min-h-screen bg-white'>
      <NavBar />
      {children}
    </div>
  )
}

export { CommonLayout }
