import { useLayoutEffect } from 'react'

const useInit = () => {
  useLayoutEffect(() => {
    // Enable dark mode
    if (localStorage.getItem('isDarkMode') === 'true') {
      import('darkreader').then(DarkReader => {
        DarkReader.enable({ brightness: 90, contrast: 100, sepia: 0 })
      })
    }
  }, [])
}

export { useInit }
