import { pick, omitBy, isNil, isEmpty } from 'lodash-es'
import cookie from '@/libs/cookie'
import { GOOGLE_CONVERSION_API } from '@/features/tracking/gtag'
import { InitGtag as InitGtagFromLibs } from '@libs-components/features'

const InitGtag = () => {
  const init = (
    <InitGtagFromLibs
      onRouterReady={router => {
        const conversionQueries = omitBy(pick(router.query, GOOGLE_CONVERSION_API.VALUES), isNil)
        if (isEmpty(conversionQueries)) return

        const currentTimestamp = new Date().getTime()
        const threeMonthMs = 3 * 30 * 24 * 60 * 60 * 1000
        cookie.set({
          key: GOOGLE_CONVERSION_API.KEY,
          value: JSON.stringify(conversionQueries),
          expires: new Date(currentTimestamp + threeMonthMs),
          domain: process.env.NEXT_PUBLIC_ROOT_DOMAIN as string,
        })
      }}
    />
  )

  return init
}

export default InitGtag
