import { isEmpty } from 'lodash-es'
import CookieUtils from '@/libs/cookie'

const UTM_PARAM = {
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
  UTM_AD_SET: 'utm_ad_set',
} as const

const ALLOWED_UTM_PARAMS = Object.values(UTM_PARAM)

const WU_UTM = 'WuUtm'

type COOKIE_UTM = Partial<{
  [key in (typeof ALLOWED_UTM_PARAMS)[number]]: string
}>

const parse = () => {
  // @ts-expect-error -- the window.location will automatically convert to string and be encoded.
  const urlSearch = new URL(decodeURIComponent(encodeURIComponent(window.location)))
  const urlParams = new URLSearchParams(urlSearch.search)
  const parsedParams: Record<string, unknown> = {}

  ALLOWED_UTM_PARAMS.forEach(key => {
    const paramValue = urlParams.get(key)

    if (paramValue) {
      parsedParams[key] = paramValue
    }
  })

  return parsedParams
}

const saveToCookie = () => {
  const utms = parse()
  if (isEmpty(utms)) return

  const UTM_DURATION_MINS = 30
  const expireAt = new Date(new Date().getTime() + UTM_DURATION_MINS * 60 * 1000)
  CookieUtils.set({
    key: WU_UTM,
    value: JSON.stringify(utms),
    expires: expireAt,
    domain: process.env.NEXT_PUBLIC_ROOT_DOMAIN as string,
  })
}

const getFromCookie = () => {
  return CookieUtils.get(WU_UTM, true) as COOKIE_UTM
}

export type { COOKIE_UTM }
export { UTM_PARAM }
export default { saveToCookie, getFromCookie, parse }
