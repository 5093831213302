import { useEffect, useState } from 'react'
import { atom, useAtomValue } from 'jotai'
import { getOrgId } from './utils'

const orgIdAtom = atom<string | 'default'>('default')
orgIdAtom.onMount = setAtom => {
  setAtom(() => getOrgId() ?? 'default')
}

const useOrgId = () => useAtomValue(orgIdAtom)
const useIsOrgMode = (isOrgModeFromServer?: boolean) => {
  const [isInitialRender, setIsInitialRender] = useState(true)
  const isOrgModeFromClient = useOrgId() !== 'default'
  useEffect(() => {
    setIsInitialRender(false)
  }, [])
  if (typeof isOrgModeFromServer !== 'undefined' && isInitialRender) {
    return isOrgModeFromServer
  }
  return isOrgModeFromClient
}

export { useOrgId, useIsOrgMode }
