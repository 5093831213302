import type { PropsWithChildren, ReactNode } from 'react'
import { useIsOrgMode } from './use-org-mode'

type ModeBasedRendererProps = PropsWithChildren<
  Partial<{ BrandComponent: () => ReactNode; isOrgModeFromServer: boolean }>
>

const ModeBasedRenderer = ({
  children,
  isOrgModeFromServer,
  BrandComponent,
}: ModeBasedRendererProps) => {
  const isOrgMode = useIsOrgMode(isOrgModeFromServer)

  if (!isOrgMode) return children

  if (BrandComponent) return <BrandComponent />

  return null
}

export { ModeBasedRenderer }
