import type { AppProps } from 'next/app'
import createEmotionCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { Provider as JotaiProvider } from 'jotai'
import { useAtomsDebugValue } from 'jotai-devtools'
import { appWithTranslation } from 'next-i18next'
import { Suspense } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import { IntercomProvider } from 'react-use-intercom'
import { LibsComponentsProvider } from '@libs-components/provider'

import apiCaller from '@/apis/utils/caller'
import { isDev } from '@libs-components/utils/env'
import { muiTheme } from '@/styles/theme'

import { GDPRModal } from '@/components/modals/GDPR'
import { CommonLayout } from '@/components/layouts/common'
import AppSeo from '@/components/elements/seo'
import TestEnvWarning from '@/components/elements/test-env-warning'
import Intercom from '@/components/intercom'
import GlobalLoader from '@/components/loader/global-loader'
import WuToastContainer from '@libs-components/components/toast/toast-container'
import AuthModalContainer from '@/features/auth/auth-modal'
import InitTracking from '@/features/tracking/InitTracking'

import '@/styles/globals.css'
import 'swiper/css'
import 'swiper/css/pagination'
import '@libs-components/components/icon/wu-font.css'

const DebugAtoms = () => {
  useAtomsDebugValue()
  return null
}

function App({ Component, pageProps }: AppProps) {
  const cache = createEmotionCache({ key: 'css', prepend: true })

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
        />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge,chrome=1' />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='format-detection' content='telephone=no' />
        <meta name='theme-color' content='#383b3d' />
        <meta
          name='google-site-verification'
          content='hzwyMaoSMpI-w07ToMQJvQCiQkVMcQHwr05_aKTNQWQ'
        />

        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Head>
      <InitTracking />
      {!isDev && (
        <Script
          id='load-clarity-script'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", '${process.env.NEXT_PUBLIC_CLARITY_ID}');
            `,
          }}
        />
      )}
      <AppSeo />
      <GDPRModal />
      <CacheProvider value={cache}>
        <IntercomProvider
          appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID as string}
          initializeDelay={5000}
        >
          <JotaiProvider>
            <DebugAtoms />
            <MuiThemeProvider theme={muiTheme}>
              <CssBaseline />
              <WuToastContainer />
              <TestEnvWarning />
              <LibsComponentsProvider apiCaller={apiCaller}>
                <Suspense fallback={<div />}>
                  <CommonLayout>
                    <Component {...pageProps} />
                    <Intercom />
                    <AuthModalContainer />
                    <GlobalLoader />
                  </CommonLayout>
                </Suspense>
              </LibsComponentsProvider>
            </MuiThemeProvider>
          </JotaiProvider>
        </IntercomProvider>
      </CacheProvider>
    </>
  )
}

export default appWithTranslation(App)
