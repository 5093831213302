import { sessionStorageManager } from '../storage/session'
import { ORG_KEY, PAGE_KEY } from './constants'

/* org id getter/setter */
const getOrgId = () => {
  return sessionStorageManager.get<string | null>(ORG_KEY)
}

const setOrgId = (orgId: string) => {
  sessionStorageManager.set(ORG_KEY, orgId)
}

const isOrgIdExists = () => {
  return !!sessionStorageManager.has(ORG_KEY)
}

/* page key getter/setter */
const getPageKey = () => {
  return sessionStorageManager.get<string | null>(PAGE_KEY)
}

const setPageKey = (pageKey: string) => {
  sessionStorageManager.set(PAGE_KEY, pageKey)
}

const isPageKeyExists = () => {
  return !!sessionStorageManager.has(PAGE_KEY)
}

export { getOrgId, setOrgId, isOrgIdExists, getPageKey, setPageKey, isPageKeyExists }
